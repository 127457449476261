.header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:fixed;
    width: 100%;
    height: 80px;
    z-index: 10;
}

.cended-img
{
    max-height:15%;
    max-width:15%;
    padding-left: 10px;
}

.header-bg
{
    background-color: rgba(0,0,0,0.85);
    transition: 0.5s;
}

.nav-menu
{
    display: flex;
}
 
.nav-menu li
{
    padding: 0 1rem;
}

.nav-menu li a
{
    padding: 1.2rem;
    font-weight: 500;
}

.hamburger
{
    display: none;
}

@media screen and (max-width:1040px) {

    /*
        left-100 hides it, active shows
    */

    .header .cended-img
    {
        max-height:20%;
        max-width:20%;
    }

    .nav-menu
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index:-3;
        transition: 0.3s;
    }

    .nav-menu.active
    {
        left:0;
    }

    .nav-menu li
    {
        padding: 1rem 0;
    }

    .nav-menu li a
    {
        font-size: 2rem;
    }
    .hamburger
    {
        display: initial;
    }
}