.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}

.into-img
{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after
{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background-color: black;
    opacity: 0.1;
}

.hero
{
    height: 100%;
    width: 100%;
}

.hero .content
{
    padding-top: 30rem;
    position: absolute;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    text-align: center;
}

.hero .content h1
{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.hero .content .icon
{
    animation: fadeInOut 5s ease-in-out infinite; /* 4s duration, ease-in-out timing, infinite loop */
    position: absolute;
    transform: translate(-50%,-100%);
    top:50%;
    left:50%;
}

.content
{
    width: 95%;
}

.hero .content p
{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}

.content .btn
{
    margin: 1rem 0.2rem;
}

.content .player
{
    float: bottom;
}

@media screen and (max-width:640px) {
    .hero .content h1{
        font-size: 3rem;
    }

    .hero .content p 
    {
        font-size: 1.4rem;
    }
}

@keyframes fadeInOut {
    0% {
      opacity: 0; /* Start with transparency */
    }
    50% {
      opacity: 1; /* Fully opaque in the middle of the animation */
    }
    100% {
      opacity: 0; /* End with transparency */
    }
  }