.footer
{
    width: 100%;
    /* padding: 6rem 0; */
    padding: 1rem 0;
    background-color: rgba(19,19,19,0.8);
    justify-content: center;}

/* .footer-container
{
    max-width: 1140px;
    margin: auto;
    disnplay: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
} */

/* .footer-container
{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
} */

.grid-container {
    /* display: grid; */

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .grid-item {
    /* background-color: #ddd; */
    padding: 20px;
    text-align: center;
    flex: 1;
  }

.item {
    width: 50%; /* Each item takes 50% of the container width */
    padding: 10px;
  }

.left{
   /* height: 100%; */
   max-width: 100%;
   display: flex; 
   flex-direction: column;
   padding: 1rem;
}

.location
{
    display: flex;
    margin-bottom: 0.8rem;
}

.location p
{
    line-height: 30px;
}

.right
{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.social
{
    margin-top: 1rem;
}

h4
{
    font-size: 1.2rem;
    padding-bottom:0.7rem;
}

p
{
    font-size: 1.2rem;
}

@media screen and(max-width:640px)
{

    .grid-container {
        grid-template-columns: repeat(2, 4fr);
        display: grid;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .footer-container
    {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}