@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Gruppo&family=Montserrat:ital,wght@0,100..900;1,100..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&family=Rubik:ital,wght@0,300..900;1,300..900&family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*
 star applies to all
*/

*
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body
{
  background: black;

  h1,h4,p,
  a
  {
    color: white;
    text-decoration:none;
  }
}

ul
{
  list-style-type: none;
}

.btn
{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248,217,15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light
{
  background: transparent;
  color: #fff;
}

.btn:hover
{
  background:rgba(255,255,255,0.2);
  color:#fff;
  transition: 0.3s;
}

/*
.volkhov-regular {
  font-family: "Volkhov", serif;
  font-weight: 400;
  font-style: normal;
}

.volkhov-bold {
  font-family: "Volkhov", serif;
  font-weight: 700;
  font-style: normal;
}

.volkhov-regular-italic {
  font-family: "Volkhov", serif;
  font-weight: 400;
  font-style: italic;
}

.volkhov-bold-italic {
  font-family: "Volkhov", serif;
  font-weight: 700;
  font-style: italic;
}

.gruppo-regular {
  font-family: "Gruppo", sans-serif;
  font-weight: 400;
  font-style: normal;
}

// <uniquifier>: Use a uniquifier for the class name
// <weight>: Use a value from 400 to 900

.cinzel-<uniquifier> {
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.fira-sans condensed-thin {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.fira-sans condensed-extralight {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.fira-sans condensed-light {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.fira-sans condensed-regular {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fira-sans condensed-medium {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.fira-sans condensed-semibold {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.fira-sans condensed-bold {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.fira-sans condensed-extrabold {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.fira-sans condensed-black {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.fira-sans condensed-thin-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.fira-sans condensed-extralight-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.fira-sans condensed-light-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.fira-sans condensed-regular-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.fira-sans condensed-medium-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.fira-sans condensed-semibold-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.fira-sans condensed-bold-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.fira-sans condensed-extrabold-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.fira-sans condensed-black-italic {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.rajdhani-light {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rajdhani-regular {
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rajdhani-medium {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.rajdhani-semibold {
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.rajdhani-bold {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-style: normal;
}

// <uniquifier>: Use a uniquifier for the class name
// <weight>: Use a value from 400 to 800

.eb-garamond-<uniquifier> {
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.pt-serif-regular {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.pt-serif-bold {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: normal;
}

.pt-serif-regular-italic {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: italic;
}

.pt-serif-bold-italic {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: italic;
}

// <uniquifier>: Use a uniquifier for the class name
// <weight>: Use a value from 300 to 900

.rubik-<uniquifier> {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

// <uniquifier>: Use a uniquifier for the class name
// <weight>: Use a value from 100 to 900

.montserrat-<uniquifier> {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}
*/